.react-pdf__Page .react-pdf__Page__canvas {
  height: auto !important;
}

@media screen and (max-width: 700px) {
  .react-pdf__Page .react-pdf__Page__canvas {
    max-width: 400px;
  }
}
@media screen and (max-width: 500px) {
  .react-pdf__Page .react-pdf__Page__canvas {
    max-width: 200px;
  }
}
